/* Variables */
$up-primary: #e40032;
$light-text: #666666;
$very-dark: #282c34;
$medium-dark: #a8a8a8;

.App {
  text-align: center;
  background: radial-gradient(circle, rgba(69,74,83,1) 0%, rgba(40,44,52,1) 35%, rgba(20,23,25,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: white;
}
h4 {
    color: white;
}

.App-link {
  color: $up-primary;
}

.logo {
    width: 150px;
}

.step-form {
    width: 20rem;
    color: $light-text;
    padding: 20px;
}

.btn.btn-up-primary {
    background-color: $up-primary;
    color: #fff;

    &:hover {
        color: #fff;
        background: darken($color: $up-primary, $amount: 5);
    }
}

.bg-very-dark {
    background-color: $very-dark;
}

.border-medium-dark {
    background-color: $medium-dark;
}

.text-medium-dark {
    color: $medium-dark;
}

.border-up-primary {
    border-color:$up-primary!important;
}

.text-up-primary {
    color: $up-primary!important;
}

@media (max-width: 480px) {
    .auth0-lock-cred-pane-internal-wrapper {
        max-height: 50vh;
    }
}

.link-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;

    &:hover {
        text-decoration: underline;
    }
  }
